const CONFIGURATION_FEATURES = {
  UPLOAD_DOCUMENTS_NEW_UI: {
    name: 'upload_documents_new_ui',
    display_name: 'Upload Documents New UI',
    description: 'Upload Documents New UI',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  REPORTER_CONTACT_EDITABLE: {
    name: 'reporter_contact_editable',
    display_name: 'Reporter Contact Editable',
    description: 'Reporter Contact Editable',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: true,
    enabled_by_default_for_demo: true,
  },
  PERFORMANCE_ASYNC_CLAIM_REFRESH: {
    name: 'performance_async_claim_refresh',
    display_name: 'Performance - Async Claim Refresh',
    description: 'Performance - Async Claim Refresh',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: true,
    enabled_by_default_for_demo: true,
  },
  PERFORMANCE_ENABLE_ANSWER_CALL_IN_SAME_TAB: {
    name: 'performance_enable_answer_call_in_same_tab',
    display_name: 'Performance - Enable to answer call in same tab',
    description: ' Enable to answer call in same tab as well',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  PERFORMANCE_SPLIT_CLAIM_LINK_AND_OPEN_IN_NEW_TAB: {
    name: 'performance_split_claim_link_and_open_in_new_tab',
    display_name: 'Performance - Split claim link and open in new tab',
    description: 'Performance - Split claim link and open in new tab',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: true,
    enabled_by_default_for_demo: true,
  },
  ADDRESS_AUTOCOMPLETE: {
    name: 'address_autocomplete',
    display_name: 'Address Autocomplete',
    description: 'Autocomplete address field',
    category: 'Long-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  PERFORMANCE_CACHE_OPERATIONAL_DETAILS: {
    name: 'performance_cache_operational_details',
    display_name: 'Performance Cache Operational Details',
    description: 'Performance Cache Operational Details',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: true,
    enabled_by_default_for_demo: true,
  },
  COMMERCIAL_POLICIES: {
    name: 'commercial_policies',
    display_name: 'Commercial Policies',
    description: 'Commercial Policies',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  QOVER_CONTACTS_UI_UNIQUE_BEHAVIOR: {
    name: 'QOVER_CONTACTS_UI_UNIQUE_BEHAVIOR',
    display_name: 'Unique Contacts Behavior - Qover',
    description: 'Add + to phones, hide email communication permission, etc.',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  CONTACT_ROLES_CONFIGURATION_SCREEN: {
    name: 'contact_roles_configuration_screen',
    display_name: 'Contact Roles Configuration Screen',
    description: 'Configuration screen for the new DB contact toles',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: true,
    enabled_by_default_for_demo: true,
  },
  TABLEAU_ACCOUNTS: {
    name: 'tableau_accounts',
    display_name: 'Tableau Accounts',
    description: 'Enables Tableau Accounts Feature',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  CLASSIFICATION: {
    name: 'physical_mail_classification',
    display_name: 'Physical Mail Classification',
    description: 'Enables physical mail classification tab',
    category: 'Long-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  INTERNAL_PAYMENT_ATTACHMENTS: {
    name: 'internal_payment_attachments',
    display_name: 'Internal Payment Attachments',
    description: 'Adds file input field to payments',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  PAYMENT_CHECKS: {
    name: 'payment_checks',
    display_name: 'Checks',
    description: 'Enables checks tab & checks generation',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  QA_DASHBOARD: {
    name: 'qa_dashboard',
    display_name: 'QA Dashboard',
    description: 'Enables QA dashboard',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  NOTES_2_0: {
    name: 'notes_2_0',
    display_name: 'Notes 2.0',
    description: 'Enable Notes 2.0',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  DA_REINSPECTION: {
    name: 'da_reinspection',
    display_name: 'Damage Assessment Field Inspection',
    description: 'Enables damage assessment field inspection',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  KPI_DASHBOARD: {
    name: 'kpi_dashboard',
    display_name: 'KPI Dashboard',
    description: 'Enables Statistics \\ KPI tables',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  OSHA_REPORTS: {
    name: 'osha_reports',
    display_name: 'Osha Reports',
    description: 'Enables Osha report tab & generation',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  T__MGM__ADVANCED_SEARCH: {
    name: 't__mgm_advanced_search',
    display_name: 'Advanced Search (MGM)',
    description: 'Enables advanced search tab',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  T__MGM__ADVANCED_SEARCH__ITRAK: {
    name: 't__mgm_advanced_search_itrak',
    display_name: 'Advanced Search (MGM) - ITrak search',
    description: 'Enables ITrak search in advanced search tab',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  T__MGM__ADVANCED_CHECKS_SEARCH: {
    name: 't__mgm_advanced_checks_search',
    display_name: 'Advanced Checks Search (MGM)',
    description: 'Enables advanced search in checks tab',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  SHOW_PAYMENT_TYPE_IN_HISTORY: {
    name: 'show_payment_type_in_history',
    display_name: 'Show Payment Type in History',
    description: 'Shows payment type column in transaction history',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  DEFENSE_ATTORNEY: {
    name: 'defense_attorney',
    display_name: 'Show Defense Attorney',
    description:
      'Changes "attorney" to plaintiff attorney, and add defense attorney & exposes templated mail for defense attorney',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  INDEMNITY_PAYMENTS_PAYEE_TEST: {
    name: 'indemnity_payments_payee_test',
    display_name: 'Indemnity Payments Payee Test',
    description: 'Adds validation to payee contact field',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  CUSTOM_CLAIMS: {
    name: 'custom_claims',
    display_name: 'Custom Claims',
    description: 'Enables custom claims tab',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  SUBRO_ASSIGNMENT_INVOLVED_AUTO_FILL: {
    name: 'subro_assignment_involved_auto_fill',
    display_name: 'Subro Assignment Involved Auto Fill',
    description: 'Changes assignment selection field',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  ENABLE_MARK_AS_UW: {
    name: 'enable_mark_as_uw',
    display_name: 'Mark Claim as UW',
    description: 'Adds option to mark claim as UW',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  ENABLE_MARK_AS_CONTROL_FILE: {
    name: 'enable_mark_as_control_file',
    display_name: 'Mark Claim as Control File',
    description: 'Adds option to mark claim as Control File',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  ENABLE_MARK_AS_DEFERRED: {
    name: 'enable_mark_as_deferred',
    display_name: 'Mark Claim as Deferred',
    description: 'Adds option to mark claim as Deferred',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  ADD_CLAIM_CONTACT_ASSOCIATION_DATE: {
    name: 'ADD_CLAIM_CONTACT_ASSOCIATION_DATE_DE',
    display_name: 'Add Claim contact association date to date export',
    description: 'Include Claim contact association date in date export',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  SUB_RESERVERS_IN_PD_AND_BI: {
    name: 'sub_reserves_in_pd_and_bi',
    display_name: 'Show Reserve Sub Calculations',
    description: 'Exposes calculation of reserve only for BI & PD exposures',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  FIELD_INSPECTION_QUEUE: {
    name: 'field_inspection_queue',
    display_name: 'Field Inspection Queue',
    description: 'Enables damage assessment field inspection, and field inspection queues',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  WC_EMPLOYEE_HR_SYSTEM_INTEGRATION: {
    name: 'wc_employee_hr_system_integration',
    display_name: 'Workday Integration',
    description: 'Enables integration with workday',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  MEDICAL_BILL_PROVIDER_PROFESSION_FIELD: {
    name: 'medical_bill_provider_profession_field',
    display_name: 'Medical Bill Provider Profession Field',
    description: 'Adds field in medical bill dialog',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  SEARCHABLE_EOB_ADJUSTMENT_REASONS: {
    name: 'searchable_eob_adjustment_reasons',
    display_name: 'Searchable Eob Adjustment Reasons',
    description: 'MGM - adds search field in medical treatment dialog',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  MEDICAL_BILL_ADJUDICATION_DRAFT: {
    name: 'medical_bill_adjudication_draft',
    display_name: 'Medical Bill Adjudication Draft',
    description: 'Adds "save as draft" button in medical bill dialog inside medical bill tab',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  CRIF_ENABLED: {
    name: 'crif_enabled',
    display_name: 'CRIF integration',
    description: 'Enables CRIF integration',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  SHOULD_VALIDATE_BEFORE_CLOSING_CLAIM: {
    name: 'should_validate_before_closing_claim',
    display_name: 'Validate Before Closing Claim',
    description: 'Disables closing claim for specific conditions',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  LOB_USER_PERMISSIONS: {
    name: 'lob_user_permissions',
    display_name: 'LOB User Permissions',
    description: 'Enables associating a user with LOB',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  T__MGM_WC_LABELED_CRITERIA: {
    name: 't__mgm_wc_labeled_criteria',
    display_name: 'WC Labeled Criteria (MGM)',
    description: 'Enables adding labels to WC claims',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  T__MGM_CONVERT_GL_EVENT_TO_CLAIM_AFTER_PAYMENTS: {
    name: 't__mgm_convert_gl_event_to_claim_after_payments',
    display_name: 'Convert GL Event To Claim After Payments (MGM)',
    description: 'Automation that converts events to claims after setting reserve to more than 0 or issuing a payment',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  T__MGM_CONVERT_GL_CLAIM_TO_EVENT_AFTER_NO_PAY_SETTLEMENT_CLOSE_REASON: {
    name: 't__mgm_convert_gl_claim_to_event_after_no_pay_settlement_close_reason',
    display_name: 'Convert GL claim to event after No Pay Settlement close reason selected (MGM)',
    description: 'Automation that converts claims to events after event after No Pay Settlement close reason selected',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  SHOULD_DETECT_DUPLICATE_MEDICAL_BILL_PAYMENTS: {
    name: 'should_detect_duplicate_medical_bill_payments',
    display_name: 'Detect Duplicate Medical Bill Payments',
    description: 'Checks similar medical bills payments before submitting',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  MGM_COMPENSATION_PERIOD_DATES: {
    name: 'mgm_compensation_period_dates',
    display_name: 'Compensation Period Dates',
    description: 'Adds fields to medical bill dialog',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  ASSIGNMENTS_CONFIGURATION: {
    name: 'assignments_configuration',
    display_name: 'Assignment Configuration',
    description: 'Enables assignment rules configuration screen in admin panel',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  POLICIES_SEARCH_PAGE: {
    name: 'policies_search_page',
    display_name: 'Policies Search Page',
    description: 'Enables policy search tab',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  SCHEDULED_DATA_EXPORT: {
    name: 'scheduled_data_export',
    display_name: 'Scheduled Data Export',
    description: 'Enables scheduling data exports',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  UK_FNOL_FIELDS_MM_STYLE: {
    name: 'uk_fnol_fields_mm_style',
    display_name: 'UK Fnol Fields MM Style',
    description: 'Enables marshmallow layout for other organizations',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  PHONE_CALLS_DASHBOARD: {
    name: 'phone_calls_dashboard',
    display_name: 'Phone Calls Dashboard',
    description: 'Enables configuring twilio settings from UI',
    category: 'Long-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  PRODUCTION_DASHBOARD: {
    name: 'production_dashboard',
    display_name: 'Production Dashboard',
    description: 'Enables production statistics dashboard',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  INSHUR_PERFORMANCE_DASHBOARDS: {
    name: 'inshur_performance_dashboards',
    display_name: 'Inshur performance dashboard',
    description: 'Enable Inshur US dashboards',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  NOTIFICATIONS_PAGINATION: {
    name: 'notifications_pagination',
    display_name: 'Notifications Pagination',
    description: 'Bugfix for notifications',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  T__PACT_CSV_INSTEAD_OF_CHECK: {
    name: 't__pact_csv_instead_of_check',
    display_name: 'Pact Csv Instead Of Check',
    description: 'pact - changes payment fields',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  FF_TABLEAU_REPORTS: {
    name: 'ff_tableau_reports',
    display_name: 'Tableau Reports',
    description: 'enables tableau reports',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  NEW_MOI_EXPERIENCE: {
    name: 'new_moi_experience',
    display_name: 'New MOI experience',
    description: 'Enables new MOI experience',
    category: 'Long-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  CONFIGURABLE_COVERAGES: {
    name: 'configurable_coverages',
    display_name: 'Configurable Coverages',
    description: 'Enables configurable coverages',
    category: 'Long-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  CONFIGURABLE_COVERAGES__POLICY_MIGRATIONS: {
    name: 'configurable_coverages_policy_migrations',
    display_name: 'Configurable Coverages - Policy Migrations',
    description: 'Policy scripts parse new coverages',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  CONFIGURABLE_COVERAGES__DISABLE_OLD_DEBT: {
    name: 'configurable_coverages_disable_old_debt',
    display_name: 'Configurable Coverages - Disable old debt',
    description: 'FF on by default, disabled the use of hard-coded coverages',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  FF_ITRAK_AUTOFILL: {
    name: 'ff_itraker_autofill',
    display_name: 'ITrak Autofill Button',
    description: 'Add option to autofill iTrak data to fnol creation',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  FF_CIP_AUTOFILL: {
    name: 'ff_cip_autofill',
    display_name: 'CIP Autofill Button',
    description: 'Add option to autofill cip data to fnol creation',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  DISABLE_REGEX_FIELD_FOR_SUB_ORGS: {
    name: 'disable_regex_field_for_sub_orgs',
    display_name: 'Disable Regex field for Sub Organizations',
    description: 'Disable selecting Regex field for Sub Organizations',
    category: 'Long-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  CONFIGURABLE_FNOL: {
    name: 'configurable_fnol',
    display_name: 'Configurable FNOL',
    description: 'Enables configuring FNOL and incident fields',
    category: 'Long-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  EXCLUDE_CLAIM_SEARCH_BY_CONTACT: {
    name: 'exclude_claim_search_by_contact',
    display_name: 'Exclude claim search by contact name',
    description: 'Exclude Claim search by contact name',
    category: 'Long-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  ORG_WITHOUT_BUSINESS_LOGIC: {
    name: 'organization_configuration_without_specific_business_logics',
    display_name: 'Organization configuration without business logic',
    description: 'Enables organization without business logic',
    category: 'Long-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  BOOST_COVERAGE_PER_EXPOSURE: {
    name: 'boost_coverage_per_exposure',
    display_name: 'Boost coverage per exposure',
    description: 'Remove special Boost configuration for exposures',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  PET_DEDUCTIBLE_UI: {
    name: 'pet_deductible_ui',
    display_name: 'Pet deductible UI',
    description: 'Add to UI deductible related additions',
    category: 'Feature',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  ONLINE_EDIT: {
    name: 'online_edit',
    display_name: 'Allow online edit of document templates',
    description: 'Integration with third party online editor to edit document templates via the browser',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  PERMISSIONS_ENFORCEMENT: {
    name: 'permissions_enforcement',
    display_name: 'Permissions Enforcement',
    description: 'Enables permissions enforcement',
    category: 'Long-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  ASSIGN_USER_TO_SUB_ORG: {
    name: 'assign_user_to_sub_org',
    display_name: 'Assign user to sub org',
    description: 'Allow assigning user to specific suborgs in the user management dialog',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  CLAIM_DELETE_ENABLED: {
    name: 'claim_delete_enabled',
    display_name: 'Enable Claim Delete (Skyworks demo)',
    description: 'Allows to delete claims',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  ENABLE_LIGHT_DUTY_TRACK: {
    name: 'enable_light_duty_track',
    display_name: 'Enable Light Duty Track',
    description: 'Allows to add employee light duty statuses',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  AUTOMATIC_EXPOSURES_RULES: {
    name: 'automatic_exposures_rules',
    display_name: 'Automatic Exposures Rules',
    description: 'Configure automatic exposure rules',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  AUTOMATIC_COMMUNICATIONS_RULES: {
    name: 'automatic_communications_rules',
    display_name: 'Automatic Communications Rules',
    description: 'Configure automatic communications rules',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  DELETE_INVOLVED_PARTIES: {
    name: 'delete_involved_parteid',
    display_name: 'Delete Involved Parties (Skyward)',
    description: 'Allow deleting involved parties',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  DATABANK_PHYSICAL_MAIL_INTEGRATION: {
    name: 'databank_physical_mail_integration',
    display_name: 'Databank physical mail integration',
    description: 'Databank physical mail integration - INTEGRATION is not GAed',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  MEDLOGIX_INTEGRATION: {
    name: 'medlogix_integration',
    display_name: 'Medlogix integration',
    description: 'Medlogix integration - INTEGRATION is not GAed',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  LOB_PHYSICAL_MAIL_INTEGRATION: {
    name: 'lob_physical_mail_integration',
    display_name: 'Lob physical mail integration',
    description: 'Lob physical mail integration - INTEGRATION is not GAed',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  HANDLE_QOVER_MULTIPLE_PAYMENT_METHODS_IN_REPORT: {
    name: 'handle_qover_multiple_payment_methods_in_report',
    display_name: 'Handle Qover multiple payments methods in report',
    description: 'When multiple payment methods are allowed the payments report file should be changed.',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  LOSS_REPORT_TEMP: {
    name: 'loss_report_temp',
    display_name: 'Loss Report Temp not GAed',
    description: 'Add loss report page - mainly for Inshur US',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  ADVANCED_SEARCH_DIALOG: {
    name: 'advanced_search_dialog',
    display_name: 'Advanced Search Dialog',
    description: 'Show Advanced Search Dialog',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  STATES_LICENSES: {
    name: 'states_licenses',
    display_name: 'Adjuster states licenses',
    description: 'Allows configuring licenses for adjusters in specific states',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  EXTERNAL_DEV_TOOLS: {
    name: 'external_developer_tools',
    display_name: 'External Developer Tools',
    description: 'External Developer Tools',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  COMMUNICATION_INSIGHT_AI: {
    name: 'communication_insight_ai',
    display_name: 'Communication insight AI',
    description: 'Communication insight AI',
    category: 'Long-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  MOVE_FAULT_ASSESSMENT_TO_MANAGEMENT_TAB: {
    name: 'move_fault_assessment_to_management_tab',
    display_name: 'Move Fault Assessment To Management Tab',
    description: 'Move fault assessment to management tab in the new fnol ui',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: true,
    enabled_by_default_for_demo: true,
  },
  ADD_EMAIL_COMMUNICATION_CONTENT_TO_DATA_EXPORT: {
    name: 'add_email_communication_content_to_data_export',
    display_name: 'Add email communication content to Data Export',
    description: 'Add body_html as a email content column to EmailCommunication Data Export',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  NEW_CLOSING_REASON: {
    name: 'new_closing_reason',
    display_name: 'Enable using exposure closing reason configuration',
    description: 'Instead of using hardcoded closing reasons enables to configure specific exposure closing reasons',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  MULTI_STATE_NOTIFICATIONS: {
    name: 'multi_state_notifications',
    display_name: 'Multi State Notifications',
    description: 'Configure notifications by states',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  DOCUMENT_AI: {
    name: 'document ai upload',
    display_name: 'Document AI',
    description: 'Document AI upload',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  COMMERCE_BANK_PAYMENT: {
    name: 'commerce_bank_payment',
    display_name: 'Commerce bank payment',
    description: 'Enable usage of commerce bank payment method',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  NOTIFICATION_OWNER_DE: {
    name: 'notification_owner_de',
    display_name: 'Notification Owner in Data Export',
    description: 'Add notification owner to data export',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  ORG_HIERARCHY_CONFIGURATION: {
    name: 'org_hierarchy_configuration',
    display_name: 'Organizational units configuration interface',
    description: 'Enable organizational units configuration interface',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  ORG_HIERARCHY_WORKFLOWS: {
    name: 'org_hierarchy_workflows',
    display_name: 'Business workflows to utilize organizational units',
    description: 'Enable business workflows to utilize organizational units',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  ALWAYS_CHECK_COMMUNICATION_PERMISSION: {
    name: 'always_check_communication_permission',
    display_name: 'Always check communication permission',
    description: 'Always check communication permission, regardless the contact type',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: true,
    enabled_by_default_for_demo: false,
  },
  LEGAL_ACTIONS: {
    name: 'legal_actions',
    display_name: 'Legal Actions Management',
    description: 'Access the legal actions management screen',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  INSHUR_UK_EMAIL_TEMPLATE: {
    name: 'inshur_uk_email_template',
    display_name: 'Inshur UK Email Template',
    description: 'Display Inshur UK MOI Email Template',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  FF_TABLEAU_CREATOR: {
    name: 'ff_tableau_creator',
    display_name: 'Tableau creator POC',
    description: 'Enable the self-service POC option on the reports and dashboards',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  FF_TABLEAU_SELF_SERVICE: {
    name: 'ff_tableau_self_service',
    display_name: 'Tableau Self Service',
    description: 'Enable the reports and dashboards v2 with the self-service option',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  CCC_INTEGRATION_DEVELOPMENT: {
    name: 'ccc_integration_development',
    display_name: 'CCC Integration Development',
    description: 'Enable the ccc integration - only for development period',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  FNOL_CONFIGURATION_INVOLVED_ENTITIES: {
    name: 'fnol_configuration_involved_entities',
    display_name: 'Fnol Configuration Involved Entities',
    description: 'Enable Fnol Configuration Involved Entities',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  CLAIM_CHAT: {
    name: 'claim_chat',
    display_name: 'Claim Chat',
    description: 'Enable the claim chat',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  FNOL_AI: {
    name: 'fnol_ai',
    display_name: 'FNOL AI',
    description: 'Enable FNOL AI',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  DISABLE_CLAIM_CHAT_POLLING: {
    name: 'disable_claim_chat_polling',
    display_name: 'Disable Claim Chat Polling',
    description: 'Kill switch for the claim chat polling',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: true,
    enabled_by_default_for_demo: false,
  },
  ITC_PRESENTATION: {
    name: 'itc_presentation',
    display_name: 'ITC Presentation',
    description: 'Enables ITC presentation features',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: true,
    enabled_by_default_for_demo: false,
  },
  NOTIFICATIONS_SEARCH_VIA_INCLUDED_HANDLER: {
    name: 'notifications_search_via_included_handler',
    display_name: 'Notification search via included handler',
    description: 'Notifications have handler cached and use it for search',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  EFFICIENT_CLAIM_FOR_FE: {
    name: 'efficient_claim_for_fe',
    display_name: 'Efficient Claim for FE',
    description: 'Make claim retrieval for FE more efficient',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: true,
    enabled_by_default_for_demo: true,
  },
  ZURICH_IRELAND_DEMO: {
    name: 'zurich_ireland_demo',
    display_name: 'Zurich Ireland Demo',
    description: 'Features for Zurich Ireland demo',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  PERFORMANCE_PERSIST_CLAIM_TABS: {
    name: 'persist_claim_tabs',
    display_name: 'Performance Persist Tabs in Claim Page',
    description: 'Persist tabs in claim page',
    category: 'Short-term Rollout',
    enabled_by_default: true,
    editable_by_support: true,
    enabled_by_default_for_demo: true,
  },
  PERFORMANCE_UPLOAD_DOCUMENT_STORED_FILE: {
    name: 'performance_upload_document_stored_file',
    display_name: 'Performance Upload Document Stored File',
    description: 'Upload Document stored file when selecting the document',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: true,
  },
  ROW_LEVEL_SECURITY: {
    name: 'row_level_security',
    display_name: 'Row Level Security (RLS)',
    description: 'Enforces row level security (RLS) for that organization',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  MIGRATE_QOVER_TO_STANDARD: {
    name: 'migrate_qover_to_stadard',
    display_name: 'Migrate qover to standard',
    description: 'Removes some of the is_qover features in the code',
    category: 'Feature',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  VECTORIZE_CLAIM_DATA: {
    name: 'vectorize_claim_data',
    display_name: 'Vectorize Claim Data',
    description: 'Activate triggers to add claim data to the vector store',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  GENERAL_RECOVERIES: {
    name: 'general_recoveries',
    display_name: 'General Recoveries',
    description: 'Enable General Recoveries',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  XACT_MOI_DOCUMENTS: {
    name: 'xact_moi_documents',
    display_name: 'Xactimate MOI Documents',
    description: 'Enable user to add documents to Xactimate assignment',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  INTERNAL_POLICY_CONFIGURATION_SCREEN: {
    name: 'internal_policy_configuration_screen',
    display_name: 'Internal Policy Configuration Screen',
    description: 'Internal Policy Configuration Screen',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  EXTERNAL_POLICY_CONFIGURATION_SCREEN: {
    name: 'external_policy_configuration_screen',
    display_name: 'External Policy Configuration Screen',
    description: 'External Policy Configuration Screen',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  COMMUNICATION_UI_2: {
    name: 'communication_ui_2',
    display_name: 'Communication UI 2.O',
    description: 'Communication UI 2.O',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  VITESSE_PAYMENT: {
    name: 'vitesse_payment',
    display_name: 'Vitesse Payment',
    description: 'Allows configuring vitesse as a payment method',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  STAT_RESERVES: {
    name: 'stat_reserves',
    display_name: 'Stat Reserves',
    description: 'Allow setting default reserves for exposures',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
  CLIVE_CLAIM_CONTEXT_EXTENDED_DATA: {
    name: 'clive_claim_context_extended_data',
    display_name: 'Clive Claim Context Extended Data',
    description: 'Add extended data to claim context',
    category: 'Short-term Rollout',
    enabled_by_default: false,
    editable_by_support: false,
    enabled_by_default_for_demo: false,
  },
} as const;

export default CONFIGURATION_FEATURES;
